import React from 'react'

const TagsInput = ({ value: values, onChange, placeholder = "" }) => {
  function handleKeyDown(e) {
    const value = e.target.value
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!value.trim()) return
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      if (!regex.test(value)) return
      onChange([...values, value])
      e.target.value = ''
    }
    if (e.key === 'Backspace' && !value) {
      if (values.length < 2) return
      values.pop()
      onChange([...values])
    }
  }

  function handleOnBlur(e) {
    const value = e.target.value
    if (!value.trim()) return
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    if (!regex.test(value)) return
    onChange([...values, value])
    e.target.value = ''
  }

  function removeTag(index) {
    onChange(values.filter((el, i) => i !== index))
  }

  return (
    <div className='flex w-full gap-2 items-center border border-gray-700 p-1 rounded-lg'>
      {values.map((tag, index) => (
        <div className='bg-gray-300 inline-block px-1 py-0.5' key={index}>
          <span className="text">{tag}</span>
          <span className='h-5 w-5 ml-1 cursor-pointer' onClick={() => removeTag(index)}>
            &times;
          </span>
        </div>
      ))}
      <input
        onKeyDown={handleKeyDown}
        onBlur={handleOnBlur}
        type="text"
        className={'w-full outline-none border-none shadow-none'}
        placeholder={placeholder}
      />
    </div>
  )
}

export default React.forwardRef((props, ref) => <TagsInput innerRef={ref} {...props} />);
