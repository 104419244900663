import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import { useNavigate } from "react-router-dom";
import Dashboard from '../components/Dashboard'
import SearchInput from '../components/atom/SearchInput'
import ExistingCustomersTable from '../components/ExistingCustomersTable'
import Pagination from '../components/atom/Pagination'
import { AuthContext } from '../context/authContext'
import apiClient from '../utils/apiClient'
import Modal from '../components/atom/Modal'

const PAGE_SIZE = 10

const ExistingCustomers = () => {
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [debouncedTerm, setDebouncedTerm] = useState(search)
  const [updatedFacilities, setUpdateFacilities] = useState([])
  const [latestPublishedDate, setLatestPublishedDate] = useState('')
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [pagination, setPagination] = useState({})
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  // Summary Data
  const [sumTenants, setSumTenants] = useState() // TOTAL ELIGIBLE TENANTS
  const [sumAvrRateInc, setSumAvrRateInc] = useState() // AVERAGE RATE INCREASE %
  const [sumRevInc, setSumRevInc] = useState() // ESTIMATED REVENUE INCREASE %
  const [sumAvrMOP, setSumAvrMOP] = useState() // AVERGE MOVE-OUT PROBABILITY

  const processData = (facilities) => {
    let sum_tenants = 0, sum_avr_rate_inc = 0, sum_rev_inc = 0, sum_avr_mop = 0, fc_length = 0
    for (const fc of facilities) {
      if (!fc.tenants.length) continue
      fc_length++
      sum_tenants += fc.tenants.length
      sum_avr_rate_inc += fc.avr_rate_increase_percent
      sum_avr_mop += fc.avr_moveout_probability * 100.0
      for (const tnt of fc.tenants) {
        sum_rev_inc += (tnt.new_rate - tnt.current_rate) * 100.0 / tnt.current_rate
      }
    }
    setSumTenants(sum_tenants)
    setSumAvrRateInc((sum_avr_rate_inc / fc_length).toFixed(1))
    setSumRevInc((sum_rev_inc / sum_tenants).toFixed(1))
    setSumAvrMOP((sum_avr_mop / fc_length).toFixed(1))

    setData(facilities)
  }

  const fetchData = async () => {
    try {
      setLoading(true)
      const token = localStorage.getItem('token')

      let dataURL = `/api/ecri?`
      if (search) dataURL += `search=${search}&`
      dataURL += `status=enabled&`
      dataURL += `page=${currentPage}&`
      dataURL += `limit=${PAGE_SIZE}`
      const response = await fetch(dataURL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const { result, pagination } = await response.json()
      processData(result)
      setPagination(pagination)
    } catch (error) {
      console.error('Error fetching data: ', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedTerm(search)
      setCurrentPage(1)
    }, 500)

    return () => {
      clearTimeout(timerId)
    }
  }, [search])

  useEffect(() => {
    fetchData()
  }, [debouncedTerm, currentPage])

  const handleChange = (e) => {
    const value = e.target.value
    setSearch(value)
  }

  const handlePagination = (page) => {
    setCurrentPage(page)
    setPagination(pagination => ({...pagination, page: page}))
  }

  const handleFacilitiesChanged = (facility_id) => {
    const facilities = [...updatedFacilities]
    if (!facilities.includes(facility_id)) facilities.push(facility_id)
    setUpdateFacilities(facilities)
  }

  const handlePublishNewRates = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const confirmPublish = () => {
    apiClient.post('/ecri/publish').then(function (response) {
      setLatestPublishedDate(moment(new Date()).format('MM/DD/YYYY, hh:mm A'));
       refreshData();
       setCurrentPage(1)
      setModalIsOpen(false);
    }).catch(function (error) {
      console.log(error)
      setModalIsOpen(false);
    })
  }

  const refreshData = () => {
    navigate('/loading?redirect=existing-customer-rate-increases')
  }

  const renderTableHeader = () => {
    return (
      <thead>
        <tr className="no-padding">
          <th colSpan={3}>
            <SearchInput placeholder={'Search or filter...'} value={search} onChange={handleChange} />
          </th>
          <th className="text-gray-600">TOTAL<br />ELIGIBLE<br />TENANTS</th>
          <th className="text-gray-600">AVERAGE<br />RATE<br />INCREASE %</th>
          <th className="text-gray-600">ESTIMATED<br />REVENUE<br />INCREASE %</th>
          <th className="text-gray-600">AVERAGE<br />MOVE-OUT<br />PROBABILITY</th>
          <th align="right">
            <div>
              <button
                className="px-8 mb-2 text-red-500 font-normal text-base flex items-center gap-4"
                onClick={refreshData}
              >
                <span className="material-symbols-rounded">
                  refresh
                </span>
                Refresh Model
              </button>
            </div>
            <div>
              <button
                className="px-8 rounded rounded-lg bg-green-600 text-white font-normal text-base"
                onClick={handlePublishNewRates}
              >
                Publish New Rates
              </button>
              <Modal
                isOpen={modalIsOpen}
              >
                <h2>Are you sure you want to publish the new rates?</h2>
                <div class="action mt-4 text-center">
                  <button
                    type="button"
                    className="border border-radius-lg border-default uppercase text-default text-sm mr-4"
                    onClick={closeModal}
                  >
                    NO
                  </button>
                  <button
                    type="button"
                    className="border border-radius-lg border-primary uppercase text-primary text-sm"
                    onClick={confirmPublish}
                  >
                    YES
                  </button>
                </div>
              </Modal>
            </div>
          </th>
        </tr>
        <tr className="no-padding">
          <th colSpan={3} align="left" className="text-sm text-slate-500 px-4 font-normal">
            Last Updated: {latestPublishedDate}
          </th>
          <th className="font-normal">{sumTenants}</th>
          <th className="font-normal">{sumAvrRateInc}%</th>
          <th className="font-normal">
            <span className="px-6 py-2 bg-green-200 rounded rounded-md">{sumRevInc}%</span>
          </th>
          <th className="font-normal">
            <span className="px-6 py-2 bg-green-200 rounded rounded-md">{sumAvrMOP}%</span>
          </th>
          <th align="right">
            <span className="py-2 font-normal text-sm text-slate-500 px-4">
              {updatedFacilities.length
                ? `${updatedFacilities.length} ${updatedFacilities.length === 1 ? 'Facility' : 'Facilities'
                } Edited`
                : ''}
            </span>
          </th>
        </tr>
        <tr className="h-4"></tr>
        <tr>
          <th colSpan={3}></th>
          <th colSpan={4} className="bg-neutral-500 rounded-t-md text-white !py-1">
            New
          </th>
          <th></th>
        </tr>
        <tr className="uppercase text-gray-600 border-t-2 border-neutral-300">
          <th className="text-left">Facility</th>
          <th className="text-left">Market</th>
          <th className="text-left">
            Eligible
            <br />
            Tenants
          </th>
          <th className="bg-neutral-500 rounded-bl-md text-left text-white">
            Average
            <br />
            Rate
            <br />
            Increase %
          </th>
          <th className="bg-neutral-500 text-left text-white">
            Average
            <br />
            Rate
            <br />
            Change $
          </th>
          <th className="bg-neutral-500 text-left text-white">
            Largest
            <br />
            Increase
          </th>
          <th className="bg-neutral-500 text-left rounded-br-md text-white">
            Average
            <br />
            Move-Out
            <br />
            Probability
          </th>
          <th>Actions</th>
        </tr>
      </thead>
    )
  }

  const renderTable = () => {
    if (loading) {
      return (
        <div className="flex w-full h-4/5 items-center justify-center">
          Loading ...
        </div>
      );
    }
    return (
      <>
        <div className="w-full rounded-2xl border-2 border-gray-200 px-6 pt-6 bg-white">
          <ExistingCustomersTable
            rows={data}
            handleChange={handleFacilitiesChanged}
            renderTableHeader={renderTableHeader}
          />
        </div>
        <Pagination
          totalPages={Math.ceil(pagination.total / PAGE_SIZE)}
          page={pagination.page}
          handlePagination={handlePagination}
        />
      </>
    );
  };

  return <Dashboard>{renderTable()}</Dashboard>;
};

export default ExistingCustomers;
