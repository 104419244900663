import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useContext } from "react";
import { AuthContext } from "../context/authContext";
import apiClient from "../utils/apiClient";

const Loading = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const redirectParam = searchParams.get("redirect");

  if (!isAuthenticated) navigate("/sign-in");

  const syncData = async () => {
    try {
      await apiClient.get("/sync-data");
      navigate("/street-rates");
    } catch (error) {
      console.log(error);
      window.alert(
        "Error refreshing data: " + error?.response?.data?.errors ||
          error.toString()
      );
    }
  };

  const syncStreetRatesData = async () => {
    try {
      await apiClient.post("/street_rates/run-python");
      navigate(`/${redirectParam}`);
    } catch (error) {
      console.log(error);
      window.alert(
        "Error refreshing data: " + error?.response?.data?.errors ||
          error.toString()
      );
    }
  };

  const syncECRIData = async () => {
    try {
      await apiClient.post("/ecri/run-python");
      navigate(`/${redirectParam}`);
    } catch (error) {
      console.log(error);
      window.alert(
        "Error refreshing data: " + error?.response?.data?.errors ||
          error.toString()
      );
    }
  };

  useEffect(() => {
    if (!redirectParam) {
      return;
    }
    if (redirectParam === "street-rates") {
      syncStreetRatesData();
    } else if (redirectParam === "existing-customer-rate-increases") {
      syncECRIData();
    } else {
      syncData();
    }
  }, [redirectParam]);

  return (
    <div class="loading-container">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <img
            src={"/assets/images/loading.svg"}
            className="h-5"
            alt="Light Bulb Icon"
            style={{ width: "80%", maxWidth: "300px", height: "50%" }}
          />
          <p>Calculating Your Rate Recommendations...</p>
        </div>
      </div>
    </div>
  );
};

export default Loading;
